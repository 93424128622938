import { Box, Container } from '@mui/material';

import config from 'config/env';

import { ImageStrapi } from 'services/api/types';

import CustomImage from 'components/@common/CustomImage/CustomImage';

import { Title, TrustedContainer, TrustedLogos } from '.';

interface TrustedOrganizationsProps {
  title?: string;
  trustedOrganizations?: ImageStrapi[];
}

const TrustedOrganizations = ({ trustedOrganizations, title }: TrustedOrganizationsProps) => {
  return (
    <Container>
      <TrustedContainer>
        <Title align="center" variant="heading05" fontWeight="800">
          {/* <FormattedMessage id="trusted_organizations.title" /> */}
          {title && title}
        </Title>
        <TrustedLogos>
          {trustedOrganizations &&
            Array.isArray(trustedOrganizations) &&
            trustedOrganizations.length > 0 &&
            trustedOrganizations.map((trustedOrganization) => (
              <Box
                key={trustedOrganization.id}
                title={trustedOrganization.imgAlt}
                sx={{
                  '& img': {
                    aspectRatio: '3/2',
                  },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CustomImage
                  src={`${config.API_BASE}${trustedOrganization?.img.data?.[0]?.attributes.url}?updatedAt=${trustedOrganization?.img?.data?.[0]?.attributes?.updatedAt}`}
                  alt={trustedOrganization.imgAlt || ''}
                  width={trustedOrganization?.img?.data?.[0]?.attributes?.width || 1000}
                  height={trustedOrganization?.img?.data?.[0]?.attributes?.height || 1000}
                />
              </Box>
            ))}
        </TrustedLogos>
      </TrustedContainer>
    </Container>
  );
};

export default TrustedOrganizations;
