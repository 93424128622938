import { makeStyles } from '@material-ui/core';
import { Stack, Theme, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getServicesByCriteria } from 'redux/slices/servicesSlice';
import { AppDispatch, RootState } from 'redux/store';

import { languageCodeMap } from 'utils/localConvert';

import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { useLocale } from 'hooks/useLocale';
import useScrollDirection from 'hooks/useScrollDirection';
import { ItemLayoutStrapi, ServiceStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoArrow } from 'components/@icons';

import {
  NavigationButton,
  NavigationMenu,
  NavigationMenuContent,
} from './NavigationGroupItem.style';

const useStyles = makeStyles(() => ({
  activated: {
    color: theme.palette.global01[400],
    fontWeight: 700,
  },
}));

interface MenuProps {
  menuData?: { group: string; items: ItemLayoutStrapi[] };
  isServicesMenu?: boolean;
  hasBanner?: boolean;
}

const NavigationGroupItem = ({ menuData, isServicesMenu, hasBanner }: MenuProps) => {
  const classes = useStyles();
  const { locale } = useLocale();
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const { isTouchDevice } = useIsTouchDevice();
  const menuHoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const services = useSelector<RootState, ServiceStrapi[]>((state) => state.serviceStore.services);
  const dispatch = useDispatch<AppDispatch>();

  const toggleMenu = (open: boolean | undefined = undefined) => {
    setIsMenuOpen((prev) => open ?? !prev);
  };

  const handleMouseEnter = useCallback(() => {
    if (menuHoverTimeout.current) clearTimeout(menuHoverTimeout.current);
    setIsMenuOpen(true);
  }, []);

  const handleMouseLeave = useCallback(
    (force: boolean = false) => {
      if (menuHoverTimeout.current) {
        clearTimeout(menuHoverTimeout.current);
      }

      menuHoverTimeout.current = setTimeout(() => {
        (!isMenuHovered || force) && setIsMenuOpen(false);
      }, 75);
    },
    [isMenuHovered],
  );

  useEffect(() => {
    if (locale) {
      loadData(languageCodeMap[locale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const loadData = async (locale: string) => {
    let getServicesQuery = {
      locale: locale,
      populate: 'deep',
    };
    dispatch(
      getServicesByCriteria(
        QueryString.stringify(getServicesQuery, {
          encodeValuesOnly: true,
        }),
      ),
    );
  };

  return (
    <>
      <NavigationButton
        endIcon={<IcoArrow />}
        variant="text"
        sx={(theme) => ({ color: theme.palette.text.primary, overflow: 'visible' })}
        onPointerDown={() => isTouchDevice && toggleMenu()}
        onPointerEnter={() => !isTouchDevice && handleMouseEnter()}
        onPointerLeave={() => !isTouchDevice && handleMouseLeave()}
        onTouchStart={() => {
          setIsMenuHovered(!isMenuHovered);
        }}
      >
        <Typography
          variant="webParagraph01"
          fontWeight={700}
          className={router.asPath.includes('services') ? classes.activated : ''}
        >
          {isServicesMenu ? <FormattedMessage id="common.service" /> : menuData?.group}
        </Typography>
      </NavigationButton>
      <NavigationMenu
        onMouseOver={() => {
          menuHoverTimeout.current && clearTimeout(menuHoverTimeout.current);
          setIsMenuHovered(true);
        }}
        onMouseLeave={() => {
          setIsMenuHovered(false);
          handleMouseLeave(true);
        }}
        $isOpen={isMenuOpen}
        $hasBanner={hasBanner || false}
      >
        <NavigationMenuContent $column={isServicesMenu ? services.length : menuData?.items.length}>
          {isServicesMenu &&
            services &&
            services.map((servicesItem: ServiceStrapi, i: number) => (
              <Link
                onClick={() => setIsMenuOpen(false)}
                href={`/services/${servicesItem.attributes.url}`}
                key={i}
              >
                <NavigationButton
                  variant="text"
                  sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography fontWeight={700} variant="webParagraph01">
                      {servicesItem.attributes.title}
                    </Typography>
                  </Stack>
                </NavigationButton>
              </Link>
            ))}
          {!isServicesMenu &&
            menuData &&
            menuData?.items.map((menuDataItem, i: number) => (
              <Link
                onClick={() => setIsMenuOpen(false)}
                href={`/${menuData?.group}/${menuDataItem.url}`}
                key={i}
              >
                <NavigationButton
                  variant="text"
                  sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography fontWeight={700} variant="webParagraph01">
                      {menuDataItem.label}
                    </Typography>
                  </Stack>
                </NavigationButton>
              </Link>
            ))}
        </NavigationMenuContent>
      </NavigationMenu>
    </>
  );
};

export default NavigationGroupItem;
