import { Button, buttonClasses, Container, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';

export const NavigationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.global01[700],
    color: theme.palette.global01[400],
  },

  [`& .${buttonClasses.endIcon}`]: {
    fill: theme.palette.global01[400],
  },
  overflow: 'visible',
}));

interface NavigationProps {
  $isOpen: boolean;
  $hasBanner: boolean;
}
interface NavigationMenuContentProps {
  $column?: number | undefined;
}

export const NavigationMenu = styled(Container, {
  shouldForwardProp: (prop) => prop !== '$isOpen' && prop !== '$hasBanner',
})<NavigationProps>(({ theme, $isOpen = false, $hasBanner = false }) => ({
  paddingInline: theme.spacing(3),
  minWidth: '100vw',
  maxHeight: 0,
  opacity: 0.5,
  zIndex: 1000,
  transition: 'max-height 0.2s ease-in-out, opacity 0.2s ease-in-out',
  position: 'absolute',
  top: $hasBanner ? 161 : 97,
  left: 0,
  right: 0,
  margin: '0 auto !important',
  backgroundColor: theme.palette.neutral01[700],
  pointerEvents: 'none',
  overflow: 'hidden',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
  ...($isOpen && {
    pointerEvents: 'auto',
    maxHeight: 180,
    opacity: 1,
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  }),
}));

export const NavigationMenuContent = styled(Container, {
  shouldForwardProp: (prop) => prop !== '$column',
})<NavigationMenuContentProps>(({ theme, $column = 4 }) => ({
  paddingBlock: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: `repeat(${$column}, 1fr)`,
  gap: theme.spacing(0),

  justifyContent: 'space-evenly',
  justifyItems: 'center',
  alignContent: 'space-evenly',
  alignItems: 'center',
}));

export const NavigationMenuItem = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  fill: theme.palette.text.primary,
  '&:hover': {
    [`& > *`]: {
      fill: theme.palette.global01[400],
      color: theme.palette.global01[400],
      backgroundColor: theme.palette.global01[700],
    },
  },
}));
