import { makeStyles } from '@material-ui/core';
import { AppBar, Box, Button, Container, Stack, Theme, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

// import { languageCodeMap } from 'utils/localConvert';
import { useLocale } from 'hooks/useLocale';
import useScrollDirection from 'hooks/useScrollDirection';
import { HeaderItemStrapi, ItemLayoutStrapi, NotificationBanner } from 'services/api/types';
import { theme } from 'theme';

import { globalModal } from 'components/@common/GlobalForm/GlobalForm';
import NavigationGroupItem from 'components/@common/NavigationGroupItemDesktop/NavigationGroupItem';
import LanguageMenu from 'components/@common/SelectDropdown';
import DirectMenu from 'components/@common/SelectDropdown/DirectMenu';
import { LogoBelgaColored } from 'components/@icons';

import { NavigationButton } from './Navigation.style';
import useMedia from 'hooks/useMedia';

const useStyles = makeStyles(() => ({
  activated: {
    color: theme.palette.global01[400],
    fontWeight: 700,
  },
}));

interface HeaderProps {
  headerData?: HeaderItemStrapi[];
  notificationBanner?: NotificationBanner;
}

const Navigation = ({ headerData, notificationBanner }: HeaderProps) => {
  const { isSmall } = useMedia();
  const classes = useStyles();
  const { locale } = useLocale();
  const router = useRouter();
  const scrollDirection = useScrollDirection();
  // const dispatch = useDispatch<AppDispatch>();

  const navTransform = useMemo(() => {
    if (['inherit', 'up'].includes(scrollDirection)) return '0px';
    if (scrollDirection === 'down') return '-100%';
  }, [scrollDirection]);

  // useEffect(() => {
  //   if (locale) {
  //     loadData(languageCodeMap[locale]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [locale]);

  // const loadData = async (locale: string) => {
  //   let getServicesQuery = {
  //     locale: locale,
  //     populate: 'deep',
  //   };
  //   dispatch(
  //     getServicesByCriteria(
  //       QueryString.stringify(getServicesQuery, {
  //         encodeValuesOnly: true,
  //       }),
  //     ),
  //   );
  // };

  const groupHeaderItemByGroup = (header: HeaderItemStrapi[]) => {
    const groupedItems: { [group: string]: ItemLayoutStrapi[] } = {};

    for (const item of header) {
      const group = item.group;
      if (groupedItems[group]) {
        groupedItems[group].push(item);
      } else {
        groupedItems[group] = [item];
      }
    }

    const result: { group: string; items: ItemLayoutStrapi[] }[] = Object.entries(groupedItems).map(
      ([group, items]) => ({
        group,
        items,
      }),
    );
    return result;
  };

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral01[700],
          borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
          transition: `transform .3s ease-in-out`,
          transform: `translateY(${navTransform})`,
        })}
      >
        {notificationBanner?.Enable && (
          <Box
            sx={{
              py: '8px',
              bgcolor: theme.palette.global01[400],
            }}
          >
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'calc(100% - 100px)',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '700',
                    color: theme.palette.neutral01[700],
                    padding: '4px 8px 4px 8px',
                    bgcolor: theme.palette.global01[300],
                    borderRadius: '100px',
                    textTransform: 'uppercase',
                    mr: 1,
                  }}
                >
                  <FormattedMessage id="banner.notification.new" />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    color: theme.palette.neutral01[700],
                    mr: 1,
                  }}
                >
                  {notificationBanner?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: theme.palette.neutral01[700],
                    maxWidth: '350px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {notificationBanner?.description}
                </Typography>
              </Box>
              {(notificationBanner?.primaryButton || notificationBanner?.secondaryButton) && (
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {notificationBanner?.primaryButton && (
                    <Link href={notificationBanner?.primaryButton.url}>
                      <Button
                        fullWidth={notificationBanner?.secondaryButton ? false : isSmall}
                        size="large"
                      >
                        {notificationBanner?.primaryButton.label}
                      </Button>
                    </Link>
                  )}
                  {notificationBanner?.secondaryButton && (
                    <Link href={notificationBanner?.secondaryButton.url}>
                      <Button
                        size="large"
                        variant="outlined"
                        sx={{
                          color: theme.palette.neutral01[700],
                          padding: '16px 12px',
                          borderRadius: '8px',
                          border: `1px solid ${theme.palette.neutral01[700]}`,
                          ':hover': {
                            border: `1px solid ${theme.palette.neutral01[700]}`,
                            opacity: 0.8,
                          },
                          whiteSpace: 'nowrap',
                          backgroundColor: 'unset',
                        }}
                      >
                        {notificationBanner?.secondaryButton.label}
                      </Button>
                    </Link>
                  )}
                </Stack>
              )}
            </Container>
          </Box>
        )}
        <Container component="nav">
          <Box
            sx={{
              height: 32,
              alignItems: 'center',
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(3),
              }}
            >
              <DirectMenu fullWidth={false} variant="text" />
              <LanguageMenu fullWidth={false} variant="text" />
            </Box>
          </Box>

          <Box
            sx={{
              height: { xs: 56, md: 64 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link href="/" aria-label="Go home">
              <LogoBelgaColored style={{ width: '132px', height: '32px' }} />
            </Link>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              display="flex"
              justifyContent="center"
              flex={1}
              sx={{
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <NavigationGroupItem isServicesMenu hasBanner={notificationBanner?.Enable} />
              {headerData &&
                groupHeaderItemByGroup(headerData).map(
                  (headerItem: { group: string; items: ItemLayoutStrapi[] }, index: number) => {
                    if (headerItem.group == 'null') {
                      return headerItem.items.map(
                        (headerItemInGroup: ItemLayoutStrapi, itemIndex: number) => {
                          return (
                            <Link key={itemIndex} href={headerItemInGroup.url || ''}>
                              <NavigationButton
                                variant="text"
                                sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                              >
                                <Typography
                                  variant="webParagraph01"
                                  fontWeight={700}
                                  className={
                                    router.asPath.includes(`${headerItemInGroup.url}`)
                                      ? classes.activated
                                      : ''
                                  }
                                >
                                  {headerItemInGroup.label}
                                </Typography>
                              </NavigationButton>
                            </Link>
                          );
                        },
                      );
                    } else {
                      return <NavigationGroupItem key={index} menuData={headerItem} />;
                    }
                  },
                )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              display="flex"
              justifyContent="flex-end"
              sx={{
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Button size="large" variant="contained" onClick={() => globalModal.show()}>
                {locale && locale === 'fr' && router.asPath.includes('jobs') ? (
                  'Postuler'
                ) : (
                  <FormattedMessage id="common.get_in_touch" />
                )}
              </Button>
            </Stack>
          </Box>
        </Container>
      </AppBar>
    </>
  );
};

export default Navigation;
