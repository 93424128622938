import { makeStyles } from '@material-ui/core';
import { Box, Button, Container, Divider, Link, Stack, Typography } from '@mui/material';
import { Fade as Hamburger } from 'hamburger-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useScreenHeight from 'hooks/useScreenHeight';
import useScrollDirection from 'hooks/useScrollDirection';
import { HeaderItemStrapi, ItemLayoutStrapi, NotificationBanner } from 'services/api/types';
import { theme } from 'theme';

import { globalModal } from 'components/@common/GlobalForm/GlobalForm';
import NavigationGroupItemMobile from 'components/@common/NavigationGroupItemMobile/NavigationMobileItem';
import LanguageMenu from 'components/@common/SelectDropdown';
import DirectMenu from 'components/@common/SelectDropdown/DirectMenu';
import { IcoClose, LogoBelgaColored } from 'components/@icons';

import {
  MenuIcon,
  MenuMobile,
  MenuMobileContent,
  MenuMobileLink,
  MobileAppBar,
} from './NavigationMobile.style';
import { useLocale } from 'hooks/useLocale';

const useStyles = makeStyles(() => ({
  activated: {
    color: theme.palette.global01[400],
    fontWeight: 700,
  },
}));

interface HeaderProps {
  headerData?: HeaderItemStrapi[];
  notificationBanner?: NotificationBanner;
}

const NavigationMobile = ({ headerData, notificationBanner }: HeaderProps) => {
  const router = useRouter();
  const classes = useStyles();
  const { locale } = useLocale();
  const scrollDirection = useScrollDirection();
  const screenHeight = useScreenHeight();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowBanner, setIsShowBanner] = useState(true);
  const toggleMenu = (open: boolean | undefined = undefined) => {
    setIsMenuOpen((prev) => open ?? !prev);
  };

  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = isMenuOpen ? 'hidden' : overflow;
    return () => {
      document.body.style.overflow = overflow;
    };
  }, [isMenuOpen]);

  useEffect(() => {
    toggleMenu(false);
  }, [router.asPath, router.locale]);

  const groupHeaderItemByGroup = (header: HeaderItemStrapi[]) => {
    const groupedItems: { [group: string]: ItemLayoutStrapi[] } = {};

    for (const item of header) {
      const group = item.group;
      if (groupedItems[group]) {
        groupedItems[group].push(item);
      } else {
        groupedItems[group] = [item];
      }
    }

    const result: { group: string; items: ItemLayoutStrapi[] }[] = Object.entries(groupedItems).map(
      ([group, items]) => ({
        group,
        items,
      }),
    );
    return result;
  };

  return (
    <MobileAppBar
      $scrollDirection={scrollDirection}
      elevation={0}
      position="sticky"
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral01[700],
        borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
      })}
    >
      <Box
        sx={(theme) => ({
          height: { xs: 56, md: 64 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: 1001,
          backgroundColor: theme.palette.neutral01[700],
          padding: theme.spacing(1, 2),
        })}
      >
        <Link href="/">
          <LogoBelgaColored style={{ width: '132px', height: '32px' }} />
        </Link>

        <MenuIcon aria-expanded={isMenuOpen} aria-label="Open the menu">
          <Hamburger duration={0.2} size={16} toggled={isMenuOpen} toggle={() => toggleMenu()} />
        </MenuIcon>
      </Box>

      {notificationBanner?.Enable && isShowBanner && (
        <Box
          sx={{
            py: 2,
            bgcolor: theme.palette.global01[400],
            position: 'relative',
            ':hover': {
              span: {
                opacity: 0.3,
              },
            },
          }}
          onClick={() => setIsShowBanner(!isShowBanner)}
        >
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Box
                component={'span'}
                sx={{
                  zIndex: 1,
                  width: '23.14px',
                  height: '23.14px',
                  backgroundColor: 'black',
                  display: 'block',
                  position: 'absolute',
                  right: 5,
                  top: 10,
                  borderRadius: '50%',
                  opacity: 0,
                  transition: '.3 ease-in-out',
                }}
              ></Box>
              <IcoClose
                style={{
                  border: `1px solid black`,
                  borderRadius: '50%',
                  zIndex: 2,
                  position: 'absolute',
                  right: 5,
                  top: 10,
                }}
              />
            </Box>
          </Box>
          <Container sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: theme.palette.neutral01[700],
                  mr: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {notificationBanner?.title}
              </Typography>
              <Typography
                sx={{ fontSize: '18px', fontWeight: '400', color: theme.palette.neutral01[700] }}
              >
                {notificationBanner?.description}
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', mt: 1, alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: theme.palette.neutral01[700],
                  padding: '4px 8px 4px 8px',
                  bgcolor: theme.palette.global01[300],
                  borderRadius: '100px',
                  textTransform: 'uppercase',
                  mr: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <FormattedMessage id="banner.notification.new" />
              </Typography>
              {notificationBanner?.secondaryButton && (
                <Link href={notificationBanner?.secondaryButton.url}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      color: theme.palette.neutral01[700],
                      padding: '16px 12px',
                      borderRadius: '8px',
                      border: `1px solid ${theme.palette.neutral01[700]}`,
                      ':hover': {
                        border: `1px solid ${theme.palette.neutral01[700]}`,
                        opacity: 0.8,
                      },
                      backgroundColor: 'unset',
                    }}
                  >
                    {notificationBanner?.secondaryButton.label}
                  </Button>
                </Link>
              )}
            </Box>
          </Container>
        </Box>
      )}

      <MenuMobile $height={screenHeight} $isOpen={isMenuOpen}>
        <MenuMobileContent>
          <Stack marginY={2} gap={2} direction="column">
            <MenuMobileLink href="/">
              <Typography
                variant="heading05"
                className={router.pathname === '/' ? classes.activated : ''}
              >
                <FormattedMessage id="common.home" />
              </Typography>
            </MenuMobileLink>
            <NavigationGroupItemMobile isServicesMenu />
            {headerData &&
              groupHeaderItemByGroup(headerData).map(
                (headerItem: { group: string; items: ItemLayoutStrapi[] }, index: number) => {
                  if (headerItem.group == 'null') {
                    return headerItem.items.map(
                      (headerItemInGroup: ItemLayoutStrapi, itemIndex: number) => {
                        return (
                          <MenuMobileLink
                            key={itemIndex}
                            href={headerItemInGroup.url || ''}
                            onClick={() => toggleMenu()}
                          >
                            <Typography
                              variant="heading05"
                              className={
                                router.asPath.includes(`${headerItemInGroup.url}`)
                                  ? classes.activated
                                  : ''
                              }
                            >
                              {headerItemInGroup.label}
                            </Typography>
                          </MenuMobileLink>
                        );
                      },
                    );
                  } else {
                    return <NavigationGroupItemMobile key={index} menuData={headerItem} />;
                  }
                },
              )}
          </Stack>

          <Stack justifyContent="flex-end" mt={4} gap={2} flexGrow={1} direction="column">
            <Stack direction="column" gap={1.5}>
              <Button fullWidth size="large" variant="contained" onClick={() => globalModal.show()}>
                {locale && locale === 'fr' && router.asPath.includes('jobs') ? (
                  'Postuler'
                ) : (
                  <FormattedMessage id="common.get_in_touch" />
                )}
              </Button>
            </Stack>
            <Divider />
            <DirectMenu fullWidth={true} variant="text" />
            <LanguageMenu fullWidth={true} variant="text" />
          </Stack>
        </MenuMobileContent>
      </MenuMobile>
    </MobileAppBar>
  );
};

export default NavigationMobile;
